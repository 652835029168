<template>

  <div class="wrapper">

    <div class="content">

      <section v-show="action_login">

        <div>
          Enter your phone number and password below to login to your existing account. Otherwise click on Register with
          the
          same details to create a new account.
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">Phone Number</label>
          <input type="tel" class="form-control" name="mobile" id="exampleInputEmail1" aria-describedby="emailHelp"
            placeholder="e.g 0712 234567" v-model="msisdn">
        </div>

        <div class="form-group">

          <div style="display: flex;justify-content: space-between;">
            <label for="exampleInputPassword1">Password</label>
          </div>

          <input type="password" class="form-control" name="password" id="exampleInputPassword1" v-model="password">

        </div>

        <div style="display: grid;grid-template-columns: 2fr 2fr; margin-left: 20px">

          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" value=""> Keep me logged in
            <span class="form-check-sign">
              <span class="check"></span>
            </span>
          </label>

          <span class="blue-txt bold-txt" @click="setGetResetCode">Forgot Password?</span>

        </div>

        <div style="display: grid;grid-template-columns: 3fr 1fr;">

          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading"
            @click="login">Login
          </button>

          <button @click="setSignup"
            style="border: 1px solid var(--black); background-color: var(--secondary); color: var(--black); font-weight: var(--font-weight-bold);"
            class="btn">Register
          </button>
        </div>

      </section>

      <section v-show="action_signup">

        <div>
          Enter your personal information below to register. Otherwise click on Login with the same details to access
          existing account.
        </div>

        <div class="form-group">
          <label for="msisdn">Phone Number</label>
          <input type="tel" class="form-control" name="mobile" id="msisdn" aria-describedby="emailHelp"
            placeholder="e.g 0712 234567" v-model="msisdn">
        </div>

        <div class="form-group">
          <label for="signupPassword">Password</label>
          <input type="password" class="form-control" name="password" id="signupPassword" v-model="password">
        </div>

        <div class="form-group">
          <label for="signupPassword1">Confirm Password</label>
          <input type="password" class="form-control" name="password_2" id="signupPassword1" v-model="password1">
        </div>

        <div style="display: grid;grid-template-columns: 3fr 1fr;">
          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading"
            @click="signup">Register</button>
          <button @click="setLogin"
            style="border: 1px solid var(--black); background-color: var(--secondary); color: var(--black); font-weight: var(--font-weight-bold);"
            class="btn">Login</button>
        </div>

      </section>

      <section v-show="action_reset_password">

        <div>
          Enter your mobile number to get a password reset code
        </div>

        <div class="form-group">
          <label for="msisdn1">Phone Number</label>
          <input type="tel" class="form-control" name="mobile" id="msisdn1" aria-describedby="emailHelp"
            placeholder="e.g 0712 234567" v-model="msisdn">
        </div>

        <div style="display: grid;grid-template-columns: 3fr 1fr;">

          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading"
            @click="getResetCode">Get Reset Code
          </button>

          <button @click="setLogin"
            style="border: 1px solid var(--black); background-color: var(--secondary); color: var(--black); font-weight: var(--font-weight-bold);"
            class="btn">Login
          </button>

        </div>

      </section>

      <section v-show="action_verify_password">

        <div>
          Enter the verification code sent via sms to activate your account
        </div>

        <div class="form-group">
          <label for="code">Verification Code</label>
          <input type="number" class="form-control" name="mobile" id="code" aria-describedby="emailHelp" v-model="code">
        </div>

        <div style="display: grid;grid-template-columns: 4fr;">

          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading"
            @click="verifyAccount">Verify Account
          </button>

        </div>

      </section>

      <section v-show="action_change_password">

        <div>
          Setup your new password below
        </div>

        <div class="form-group">
          <label for="code1">Password Reset Code</label>
          <input type="number" class="form-control" name="mobile" id="code1" aria-describedby="emailHelp" v-model="code"
            disabled>
        </div>

        <div class="form-group">
          <label for="signupPassword3">New Password</label>
          <input type="password" class="form-control" name="password" id="signupPassword3" v-model="password">
        </div>

        <div class="form-group">
          <label for="signupPassword4">Confirm Password</label>
          <input type="password" class="form-control" name="password_2" id="signupPassword4" v-model="password1">
        </div>

        <div style="display: grid;grid-template-columns: 4fr;">

          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading"
            @click="changePassword">Change Password
          </button>

        </div>

      </section>

    </div>

    <BottomFooter></BottomFooter>

    <bottom-navigation active-bets="0" page="login"></bottom-navigation>

  </div>

</template>

<script>
import BottomFooter from "@/components/BottomFooter";
import BottomNavigation from "@/components/BottomNavigation";
import axios from "@/services/api";

export default {
  name: 'Reset',
  components: {
    BottomFooter,
    BottomNavigation
  },
  comments: {

  },
  data: function () {

    return {
      msisdn: '',
      password: '',
      password1: '',
      error: [],
      warning: [],
      success: [],
      loading: '',
      action_login: false,
      action_signup: false,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: true,
      code: '',
      placeBet: 0,
    }
  },
  methods: {
    login: function () {

      this.reset();

      if (this.msisdn.length < 9) {

        this.$toast.open({
          message: 'Please enter a valid mobile number',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.password.length < 4) {

        this.$toast.open({
          message: 'Please enter a password of more than 4 characters',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password
      }))
        .then(res => {

          vm.loading = '';
          var profile = res.data.message;
          vm.setProfile(profile);
          vm.setSuccess("Success", "Login successful");
          // go to previous page

          if (parseInt(vm.placeBet) === 1) {

            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:betslip:show');

          } else {

            vm.goBack();

          }

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          }
          else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })

    },
    signup: function () {

      this.reset();

      if (this.msisdn.length < 9) {

        this.$toast.open({
          message: 'Please enter a valid mobile number',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.password.length < 4) {

        this.$toast.open({
          message: 'Please enter a password of more than 4 characters',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.password !== this.password1) {

        this.$toast.open({
          message: 'Your passwords does not match',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_SIGNUP;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password
      }))
        .then(res => {

          vm.loading = '';
          console.log(JSON.stringify(res));
          var msg = res.data.message;
          vm.setSuccess("Signup successful", msg);
          // go to password verification page
          vm.setVerifyAccount();

        })
        .catch(err => {

          vm.loading = '';

          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          }
          else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })

    },
    getResetCode: function () {

      this.reset();

      if (this.msisdn.length < 9) {

        this.$toast.open({
          message: 'Please enter a valid mobile number',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn)
      }))
        .then(res => {

          vm.loading = '';
          console.log(JSON.stringify(res));

          // vm.setSuccess("Success", "Password reset code has been send to your phone");

          this.$toast.open({
            message: 'Password reset code has been send to your phone',
            type: 'success',
            position: 'top'
          });
          // go to password reset page
          vm.setChangePassword();

        })
        .catch(err => {

          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          }
          else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })

    },
    changePassword: function () {

      this.reset();

      if (this.password.length < 4) {

        this.$toast.open({
          message: 'Please enter a password of more than 4 characters',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.password !== this.password1) {

        this.$toast.open({
          message: 'Your passwords does not match',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.code.length < 4) {

        this.$toast.open({
          message: 'Please enter a valid verification code',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        old_password: this.code
      }))
        .then(res => {

          console.log(JSON.stringify(res));

          vm.loading = '';
          // vm.setSuccess("Password Changed", "Your password has been changed successfully,. Please login to continue");
          this.$toast.open({
            message: 'Your password has been changed successfully,. Please login to continue',
            type: 'success',
            position: 'top'
          });

          // go to login page
          vm.setLogin();

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          }
          else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })

    },
    verifyAccount: function () {

      this.reset();

      if (this.code.length < 4) {

        this.$toast.open({
          message: 'Please enter a valid verification code',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_VERIFY;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        code: this.code
      }))
        .then(res => {

          vm.loading = '';
          var profile = res.data.message;
          vm.setProfile(profile);

          // vm.setSuccess("Congratulations!!", "Your account has been verified successfully");
          this.$toast.open({
            message: 'Your account has been verified successfully',
            type: 'success',
            position: 'top'
          });

          if (parseInt(vm.placeBet) === 1) {

            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:betslip:show');

          } else {

            vm.goHome();

          }

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          }
          else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })

    },
    setSignup: function () {

      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setLogin: function () {

      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setGetResetCode: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;

    },
    setChangePassword: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setVerifyAccount: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;

    },
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "join");
    this.placeBet = this.getValue("placeBet");

  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // react to route changes...
        this.msisdn = to.params.msisdn;
        this.code = to.params.code;
      }
    },
  },
}
</script>